'use strict';

/**
 * CSS global modificators
 * They used to add styles to the components states that controlled via JS logic.
 * All constants should be synced with _cssconstants.scss
 */
module.exports = {
    ACTIVE: 'm-active',
    DISABLED: 'm-disabled',
    COMPLETE: 'm-complete',
    INACTIVE: 'm-inactive',
    EXPANDED: 'm-expanded',
    COLLAPSED: 'm-collapsed',
    SELECTED: 'm-selected',
    VISUALLY_HIDDEN: 'h-visually-hidden',
    VISUALLY_HIDDEN_SM: 'h-visually-hidden-sm',
    INVISIBLE: 'h-invisible',
    HIDDEN: 'd-none',
    SHOW_BLOCK: 'd-block',
    VALID: 'is-valid',
    INVALID: 'is-invalid',
    FORM_GROUP_VALID: 'form-group--valid',
    FORM_GROUP_INVALID: 'form-group--invalid',
    LTR: 'ltr',
    TRUNCATED: 'm-truncated',
    UNTRUNCATED: 'm-untruncated',
    LOADING: 'm-loading',
    SELECT_OPENED: 'm-select-opened',
    SHOW: 'show',
    HIDE: 'hide',
    SCROLL_DISABLE: 'h-disable-scroll'
};
