'use strict';

const cssConstants = require('../utils/cssConstants');

const selectors = {
    header: '.js-header',
    headerToggler: '.js-header-toggler'
};

module.exports = {
    menuMobile: function () {
        // Toggler Btn
        $(document).on('click', selectors.headerToggler, function (e) {
            e.preventDefault();
            e.stopPropagation();

            $(selectors.header).toggleClass(cssConstants.ACTIVE);
        });

    }
};
