'use strict';

const $cache = {
    phoneInput: $('.js-phone-field')
};

module.exports = function() {
    $cache.phoneInput.on('blur keyup', function () {
        var val = $(this).val() || '';
        var isMandatory = $(this).attr('required') === 'required';
        var firstDigit = val.slice(0,1);

        if (!isMandatory && val==='') {
            return;
        }

        if(firstDigit !== '0' && val.length<10) {
            $(this).val('0'+val);
        } else if(firstDigit !== '0' && val.length>=10) {
            $(this).val('0'+val.slice(0,9));
        }
    });
};