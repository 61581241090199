/* eslint-disable func-names */

'use strict';

window.jQuery = require('jquery');
window.$ = require('jquery');
var processInclude = require('./util');

$(function () {
    processInclude(require('./components/header'));
    processInclude(require('./components/clientSideValidation'));
    processInclude(require('./components/tooltip'));
    processInclude(require('./components/priceChart'));
    processInclude(require('./components/phoneInput'));
    processInclude(require('./thirdParty/tarteaucitron')); // Cookie Consent
});

require('./thirdParty/bootstrap');
require('./components/spinner');
