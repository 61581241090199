'use strict';

module.exports = function () {
    $('.js-hf-tooltip').on('mouseenter focusin', function () {
        $(this).find('.hf-tooltip-text').removeClass('d-none');
    });

    $('.js-hf-tooltip').on('mouseleave focusout', function () {
        $(this).find('.hf-tooltip-text').addClass('d-none');
    });
};

