'use strict';

var Chart = require('chart.js/auto');
var moment = require('moment'); 
var chartData = $('#priceHistoryData').length ? JSON.parse($('#priceHistoryData').val() || {}): {};

var ctxConfig = {};
var ctx = $('#chart-prices');
ctxConfig = ctx.data('config');

const getOrCreateLegendList = (chart, id) => {
    const legendContainer = document.getElementById(id);
    let listContainer = legendContainer.querySelector('ul');

    if (!listContainer) {
        listContainer = document.createElement('ul');
        listContainer.style.display = 'flex';
        listContainer.style.flexDirection = 'row';
        listContainer.style.margin = 0;
        listContainer.style.padding = 0;

        legendContainer.appendChild(listContainer);
    }

    return listContainer;
};

const htmlLegendPlugin = {
    id: 'htmlLegend',
    afterUpdate(chart, args, options) {
        const ul = getOrCreateLegendList(chart, options.containerID);

        // Remove old legend items
        while (ul.firstChild) {
            ul.firstChild.remove();
        }

        // Reuse the built-in legendItems generator
        const items = chart.options.plugins.legend.labels.generateLabels(chart);

        items.forEach(item => {
            const li = document.createElement('li');

            // Color box
            const boxSpan = document.createElement('span');
            boxSpan.style.background = item.fillStyle;
            boxSpan.style.borderColor = item.strokeStyle;
            boxSpan.style.borderWidth = item.lineWidth + 'px';

            // Tooltip
            const tooltip = document.createElement('a');

            const tooltipToggle = document.createElement('span');
            tooltipToggle.classList.add('icon', 'icon-circle-info');
            tooltipToggle.setAttribute('aria-hidden', true);

            const tooltipTextContainer = document.createElement('span');

            const tooltipText = ctxConfig.textTooltipHelp;
            const tooltipTextNode = document.createTextNode(tooltipText);
            tooltipTextContainer.appendChild(tooltipTextNode);

            tooltip.appendChild(tooltipToggle);
            tooltip.appendChild(tooltipTextContainer);

            // Text
            const textContainer = document.createElement('p');
            textContainer.style.color = item.fontColor;
            textContainer.style.textDecoration = item.hidden ? 'line-through' : '';

            const text = document.createTextNode(item.text);
            textContainer.appendChild(text);

            li.appendChild(boxSpan);
            li.appendChild(textContainer);
            textContainer.appendChild(tooltip);
            ul.appendChild(li);
        });
    }
};

const getOrCreateTooltip = (chart) => {
    let tooltipEl = chart.canvas.parentNode.querySelector('div');

    if (!tooltipEl) {
        tooltipEl = document.createElement('div');
        tooltipEl.id = 'chart-tooltip';
        tooltipEl.style.opacity = 1;

        chart.canvas.parentNode.appendChild(tooltipEl);
    }

    return tooltipEl;
};

const modifyPriceData = (data) => {
    return data.map((item) => {
        var price = item.replace(',', '.');

        if (price === '0.00') {
            price = null;
        } else if (price < 10) {
            price *= 1000;
        }
        
        return price;
    });
}

const externalTooltipHandler = (context) => {
    // Tooltip Element
    const {chart, tooltip} = context;
    const tooltipEl = getOrCreateTooltip(chart);

    // Hide if no tooltip
    if (tooltip.opacity === 0) {
        tooltipEl.style.opacity = 0;
        return;
    }

    // Set Text
    if (tooltip.body) {
        const titleLines = tooltip.title || [];
        const bodyLines = tooltip.body.map(b => b.lines);
        let contentTitles = '';
        let contentItems = '';

        titleLines.forEach(title => {
            let date = moment(title).format("D MMMM	YYYY");
            contentTitles += '<div class="tooltip-title">' + ctxConfig.labelTooltipWeek + ' <span>' + date + '</span></div>';
        });

        bodyLines.forEach((body, i) => {
            let labels = [ctxConfig.labelTooltipPrice, ctxConfig.labelTooltipRefPrice];
            let price = body.toString().split(':')[1].trim().replace(',', ''); // separate price
            price = (+price).toFixed(2).replace('.', ','); // format price

            contentItems += '<div class="tooltip-item"><span class="tooltip-label">' + labels[i] + '</span><span class="tooltip-value">' + price + '€/1000l</span></div>';
        });

        const tooltipContent = '<div class="tooltip-content">' + contentTitles + '' + contentItems + '</div>';
        tooltipEl.innerHTML = tooltipContent;
    }

    const {offsetLeft: positionX, offsetTop: positionY} = chart.canvas;

    // Display, position, and set styles for font
    tooltipEl.style.opacity = 1;
    tooltipEl.style.left = positionX + tooltip.caretX + 'px';
    tooltipEl.style.top = positionY - 125 + tooltip.caretY + 'px';

    tooltipEl.style.font = tooltip.options.bodyFont.string;
    tooltipEl.style.padding = tooltip.options.padding + 'px ' + tooltip.options.padding + 'px';
};

var charts = {
    priceChart: function () {
        moment.locale('fr', {
            months: ctxConfig.months.split('_'),
            monthsShort: ctxConfig.monthsShort.split('_')
        });

        var labels = chartData ? chartData.dates : [];
        var dataHf = chartData ? modifyPriceData(chartData.hfPrices) : [];
        var dataAverage = chartData ? modifyPriceData(chartData.frPrices) : [];
        var currentDate = new Date();
        var graphPrices = new Chart(ctx, { // eslint-disable-line
            type: 'line',
            data: {
                labels: labels,
                datasets: [{
                    label: ctxConfig.labelPrice,
                    data: dataHf,
                    borderWidth: 2,
                    borderColor: "#fb7a00",
                    backgroundColor: "#fb7a00",
                    fill: false,
                    pointStyle: 'circle',
                    pointRadius: 0,
                    pointHoverRadius: 5,
                    hitRadius: 15,
                    gridLines: {
                        display: false
                    }
                },
                {
                    label: ctxConfig.labelRefPrice,
                    data: dataAverage,
                    borderWidth: 2,
                    borderColor: "#85d4cc",
                    backgroundColor: "#85d4cc",
                    fill: false,
                    pointStyle: 'circle',
                    pointRadius: 0,
                    pointHoverRadius: 5,
                    hitRadius: 1
                }]
            },
            options: {
                layout: {
                    padding: {
                        right: 10
                    }
                },
                clip: false,
                scales: {
                    x: {
                        id: "months",
                        grid: {
                            display: false
                        },
                        ticks: {
                            autoSkip: false,
                            labelOffset: -5,
                            maxRotation: 0,
                            sampleSize: 1,
                            callback: function (label) {
                                var newDate = new Date(labels[label]);
                                var currentMonth = currentDate.getMonth();
                                var newMonth = newDate.getMonth();
                                if (currentMonth !== newMonth) {
                                    currentDate = newDate;
                                    var date = moment(labels[label]).format("MMM");
                                    return date;
                                }
                                return "";
                            }
                        }
                    },
                    y: {
                        id: "prices",
                        min: ctxConfig.minPrice || 0,
                        max: ctxConfig.maxPrice || 2000,
                        grid: {
                            display: false
                        },
                        ticks: {
                            stepSize: ctxConfig.stepSize || 100,
                            callback: function (label) {
                                return (+label).toFixed(2).replace('.', ',');
                            }
                        }
                    }
                },
                interaction: {
                    mode: 'index',
                    intersect: false,
                },
                plugins: {
                    htmlLegend: {
                        // ID of the container to put the legend in
                        containerID: 'chart-prices-legend'
                    },
                    legend: {
                        display: false,
                    },
                    tooltip: {
                        enabled: false,
                        external: externalTooltipHandler
                    }
                },
                locale: 'en-UK'
            },
            plugins: [htmlLegendPlugin],
        });
    }
};

if ($('#chart-prices').length !== 0) {
    charts.priceChart();
}
