'use strict';

const cssConstants = require('../utils/cssConstants');

/**
 * Display error message.
 * @param {string} selector - selector of input field
 * @param {string} msg - error message 
 */
function showError (selector, msg) {
    var $input = $(selector);
    var $formGroup = $input.closest('.form-group');
    $input.addClass('error');
    var $nextBlock = $input.next();
    if ($formGroup.length) {
        $input.addClass(cssConstants.INVALID);
        $formGroup.addClass(cssConstants.FORM_GROUP_INVALID);
        if ($input.val().length) {
            $input
                .closest('.js-field-container')
                .find('.invalid-feedback')
                .text(msg);
        }
    } else if ($nextBlock.hasClass('error-message')) {
        $nextBlock
            .removeClass(cssConstants.HIDDEN)
            .text(msg);
    } else {
        $input
            .after(`
                <div class="error-message">
                    ${msg}
                </div>`
            );
    }
}

/**
 * Display error message.
 * @param {string} selector - selector of input field
 */
function hideError (selector) {
    var $input = $(selector);
    var $formGroup = $input.closest('.form-group');

    if ($formGroup.length) {
        $input.removeClass(cssConstants.INVALID);
        $formGroup.removeClass(cssConstants.FORM_GROUP_INVALID);
    } else {
        $input.removeClass('error');
        var $nextBlock = $input.next();
        if ($nextBlock.hasClass('error-message')) {
            $nextBlock.addClass(cssConstants.HIDDEN);
        }
    }
}

module.exports = {
    showError: showError,
    hideError: hideError
};
