'use strict';

const cssConstants = require('../utils/cssConstants');
const errorManager = require('./error');
var invalidFields = [];

/**
 * Validate whole form. Requires `this` to be set to form object
 * @param {jQuery.event} event - Event to be canceled if form is invalid.
 * @returns {boolean} - Flag to indicate if form is valid
 */
function validateForm(event) {
    var valid = true;
    var $formGroup;
    var $fieldError;

    // eslint-disable-next-line no-use-before-define
    clearForm($(this));

    // Validates password confirmation value before form submit even the field validity is valid
    var $pwdConfirmField = $(this).find('.js-password-confirm');
    if($pwdConfirmField.length && $pwdConfirmField.val() !== $('.js-password').val()) {
        if (event) {
            event.preventDefault();
            event.stopPropagation();
            event.stopImmediatePropagation();
        }
        $formGroup = $pwdConfirmField.parents('.form-group');
        $fieldError = $formGroup.find('.invalid-feedback');
        $pwdConfirmField.addClass(cssConstants.INVALID);
        $formGroup.addClass(cssConstants.FORM_GROUP_INVALID);
        $fieldError.text($pwdConfirmField.data('confirm-error'));
        return false;
    }

    var $postalCodeField = $(this).find('.js-address-postalcode.editable-field');
    var $cityField = $(this).find('.js-address-city.editable-field');
    var $containerRow = $postalCodeField.closest('.form-group-row');

    // Validates postalcode and city values before form submit even the field validity is valid
    if($containerRow.length && !$containerRow.is('.valid')) {
        if (event) {
            event.preventDefault();
            event.stopImmediatePropagation();
        }

        $formGroup = $containerRow.find('.form-group');
        $formGroup.addClass(cssConstants.FORM_GROUP_INVALID);

        errorManager.showError($postalCodeField, $postalCodeField.data('inconsistency-error'));
        errorManager.showError($cityField, $cityField.data('inconsistency-error'));

        valid = false;
    }

    if (this.checkValidity && !this.checkValidity()) {
        // safari
        valid = false;
        if (event) {
            event.preventDefault();
            event.stopPropagation();
            event.stopImmediatePropagation();
        }
        $(this)
            .find('input, select, textarea')
            .each(function () {
                if (!this.validity.valid) {
                    $(this).trigger('invalid', this.validity);
                }
            });
    }
    return valid;
}

/**
 * Remove all validation. Should be called every time before revalidating form
 * @param {element} form - Form to be cleared
 * @returns {void}
 */
function clearForm(form) {
    $(form)
        .find('.form-control, .form-select, .form-check-input, .btn-check, .form-input')
        .filter(`.${cssConstants.INVALID}`)
        .removeClass(cssConstants.INVALID);
    $(form).find('.form-group.' + cssConstants.FORM_GROUP_INVALID).removeClass(cssConstants.FORM_GROUP_INVALID);    
}

/**
 * Checks if the email value entered is correct format
 * @param {string} email - email string to check if valid
 * @returns {boolean} Whether email is valid
 */
function validateEmail(email) {
    // eslint-disable-next-line no-useless-escape
    var regex = /^(?:[\w]+){1}[+\w\.\-]*@(?:[\w\-]+)((\.(\w){2,9})+)$/;
    return regex.test(email);
}

/**
 * Scrolls to first invalid field on form
 * @param {Object} el - field has error
 */
function scrollToInvalid(el) {
    var $firstFieldError = el || '';

    if($firstFieldError.length) {
        var fieldPosY = $firstFieldError.offset().top;
        var windowPosY = $(window).scrollTop();
        if (windowPosY >= fieldPosY) {
            // if input is out of the view, page scrolls
            $('html, body').animate({
                scrollTop: fieldPosY - 40 // minus to include label
            }, 200);
        }
    }
}

module.exports = {
    validateDigitsOnlyFields: function () {
        $('body').on('input', '.js-digits-only', function () {
            this.value = this.value.replace(/[^0-9]/g, '');
        });
    },

    invalid: function () {
        $('form input, form select, form textarea').on('invalid', function (e) {
            e.preventDefault();
            this.setCustomValidity('');
            if (!this.validity.valid) {
                var validationMessage = this.validationMessage;
                var $formGroup = $(this).parents('.form-group');
                var $fieldError = $formGroup.find('.invalid-feedback');

                $(this).removeClass(cssConstants.VALID).addClass(cssConstants.INVALID);
                $formGroup.removeClass(cssConstants.VALID).addClass(cssConstants.FORM_GROUP_INVALID);

                if (this.validity.patternMismatch && $(this).data('pattern-mismatch')) {
                    validationMessage = $(this).data('pattern-mismatch');
                }

                // Client-side email pattern matching
                if (
                    $(this).attr('type') === 'email' &&
                    $(this).data('pattern-mismatch') &&
                    !validateEmail($(this).val)
                ) {
                    validationMessage = $(this).data('pattern-mismatch');
                }

                if (
                    this.validity.patternMismatch &&
                    $(this).attr('type') !== 'email' &&
                    $(this).data('pattern-mismatch')
                ) {
                    validationMessage = $(this).data('pattern-mismatch');
                }
                if (
                    (this.validity.rangeOverflow ||
                        this.validity.rangeUnderflow) &&
                    $(this).data('range-error')
                ) {
                    validationMessage = $(this).data('range-error');
                }
                if (
                    (this.validity.tooLong || this.validity.tooShort) &&
                    $(this).data('range-error')
                ) {
                    validationMessage = $(this).data('range-error');
                }
                if (
                    this.validity.valueMissing &&
                    $(this).data('missing-error')
                ) {
                    validationMessage = $(this).data('missing-error');
                }
                
                // Password confirmation field validation
                if (
                    !this.validity.valueMissing &&
                    $(this).attr('type') === 'password' &&
                    $(this).hasClass('js-password-confirm') &&
                    $(this).val() !== $('.js-password').val()
                ) {
                    validationMessage = $(this).data('confirm-error');
                }
                $fieldError.text(validationMessage);
                invalidFields.push($(this));
            }
        });
    },

    submit: function () {
        $('form').on('submit', function (e) {
            return validateForm.call(this, e);
        });
    },

    buttonClick: function () {
        $('body').on('click', 'form button[type="submit"], form input[type="submit"]',
            function () {             
                // Focuses on first invalid input   
                setTimeout(function(){
                    // Delayed since invalid input loads
                    if(invalidFields.length) {
                        scrollToInvalid(invalidFields[0]);
                        invalidFields = [];
                    }
                }, 500);
                // clear all errors when trying to submit the form
                clearForm($(this).parents('form'));
            }
        );
    },

    functions: {
        validateForm: function (form, event) {
            return validateForm.call(form, event || null);
        },
        clearForm: clearForm,
        scrollToInvalid: scrollToInvalid
    }
};
